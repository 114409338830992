export const titles = {
  addRecipient: 'Aggiungi destinatario',
  addRecipients: 'Aggiungi destinatari',
  agreementAdditionalNodeTerms: 'Termini Addizionali ACCORDO NODO DISTRIBUTORE',
  agreementAdditionalTerms: 'Termini Addizionali',
  agreementBuyService: 'Accordo di acquisto di beni e servizi tecnologici (“Accordo”) Termini e condizioni generali',
  agreementNodeDistributor: 'ACCORDO NODO DISTRIBUTORE (di seguito “Accordo“) Termini e condizioni generali Versione nr. 1 /data pubblicazione 15 Aprile 2024',
  agreements: 'Contrattualistica',
  allCredentials: 'Tutte le credenziali',
  allYourBackup: 'I tuoi eArchive',
  archive: 'Archivio',
  archiveDocument: 'Archiviazione sicura dei documenti',
  attributes: 'Attributi',
  availables: 'Disponibili',
  bin: 'Cestino',
  buyCcc: 'Acquista CCC',
  certificateBlockchain: 'Certificato di notarizzazione Blockchain ed eArchive',
  code: 'Codice',
  confirmRequest: 'Conferma richiesta',
  contacts: 'Contatti',
  copyright: 'Copyright',
  credentialDetail: 'Dettaglio credenziale',
  credentials: 'Credenziali',
  delegate: 'Delega #{0}',
  delegations: 'Deleghe',
  deliverDocument: 'Consegna elettronica del documento',
  delivery: 'eDelivery',
  details: 'Dettagli',
  documents: 'Documenti',
  dossiers: 'Fascicoli',
  dossiersList: 'Elenco fascicoli',
  eArchive: 'eArchive',
  eArchiveDetail: 'eArchive #{0}',
  eDeliveries: 'eDeliveries',
  eDelivery: 'eDelivery',
  eDeliveryDetail: 'eDelivery #{0}',
  eIdWallet: 'eID Wallet',
  eSign: 'eSign',
  earn: 'Earn',
  editContact: 'Modifica contatto',
  editDossier: 'Modifica fascicolo',
  file: 'File',
  folders: 'Cartelle',
  invite: 'Invita',
  inviteContact: 'Invita un contatto',
  invoicingData: 'Dati fatturazione',
  joinTheFirst: 'Entra a far parte del primo',
  linkDossier: 'Collega fascicolo',
  logo: 'Logo',
  manageFolders: 'Gestisci cartelle',
  manageGroups: 'Gestisci gruppi',
  manageTags: 'Gestisci tag',
  me: 'Me',
  memberDashboard: 'Member dashboard',
  message: 'Messaggio',
  metadata: 'Metadata',
  myContacts: 'Miei contatti',
  newArchive: 'Nuovo archivio',
  newContact: 'Nuovo contatto',
  newDossier: 'Nuovo fascicolo',
  newEArchive: 'Nuovo eArchive',
  newEDelivery: 'New eDelivery',
  newSharedoc: 'Nuova sharedoc',
  nft: 'NFT',
  nftDetail: 'NFT #{0}',
  notarize: 'Notarizza',
  parameters: 'Parametri',
  preview: 'Anteprima',
  primaryColor: 'Colore primario',
  proof: 'Proof',
  protected: 'Protetti',
  purchase: 'Acquisto',
  purchaseMembership: 'Acquista la membership annuale',
  recap: 'Riepilogo',
  receipt: 'Ricevuta',
  received: 'In arrivo',
  receivedDetail: 'In arrivo #{0}',
  recipient: 'Destinatario',
  reward: 'Ricompensa #{0}',
  rewards: 'Ricompense',
  selectDocument: 'Seleziona un documento',
  selectType: 'Scegli il tipo',
  sendCredits: 'Invia crediti',
  sent: 'Inviate',
  settings: 'Impostazioni',
  sharedoc: 'Sharedoc',
  sharedocSendNow:'Invia una Sharedoc',
  sharedocs:'Sharedocs',
  signed: 'Firmati',
  specifications: 'Specifiche',
  stake: 'Stake',
  stakeDetail: 'Stake #{0}',
  summary: 'Riepilogo',
  surcharge: 'Sovrapprezzo',
  swap: 'Swap',
  swapSummary: 'Riepilogo Swap',
  tags: 'Etichette',
  theme: 'Tema',
  timestampFile: 'Notarizza file',
  toSigned: 'Da firmare',
  tokens: 'Tokens',
  tokensReceived: 'Tokens ricevuti',
  tokensSent: 'Tokens inviati',
  transferNft: 'Trasferisci NFT',
  unbondings: 'Sblocchi',
  undelegate: 'Sblocco #{0}',
  upgrade: 'Upgrade',
  visibility: 'Visibilità',
  whatCanDo: 'Cosa potro’ fare con la membership di commercio.network?',
  yourInviteCode: 'Il tuo codice d\'invito',
  yourNft: 'I tuoi NFT',
  yourWallet: 'Il tuo Wallet',
};
