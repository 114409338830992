import {
  mdiArrowBottomLeftBoldOutline,
  mdiArrowTopRightBoldOutline,
  mdiSortAlphabeticalAscending,
  mdiSortAlphabeticalDescending,
  mdiSortAscending,
  mdiSortCalendarAscending,
  mdiSortCalendarDescending,
  mdiSortDescending,
  mdiViewGridOutline,
  mdiViewListOutline,
} from '@mdi/js';

const COLORS_LIST = Object.freeze([
  'red',
  'pink',
  'purple',
  'deep-purple',
  'indigo',
  'blue',
  'light-blue',
  'cyan',
  'teal',
  'green',
  'light-green',
  'lime',
  'yellow',
  'amber',
  'orange',
  'deep-orange',
  'brown',
  'blue-grey',
]);

const COUNTRIES = Object.freeze({
  IT: 'IT',
});

const FILE_TASKS = Object.freeze({
  SHAREDOC: 'sharedoc',
  VAULT: 'vault',
});

const NFT_TYPES = Object.freeze({
  BASE: 'base',
  METADATA_ON_CHAIN: 'metadata_on_chain',
});

const POSITIONINGS = Object.freeze({
  BOTTOM: 'bottom',
  CENTER: 'center',
  GRID: 'grid',
  LEFT: 'left',
  LIST: 'list',
  RIGHT: 'right',
  TOP: 'top',
});

const SORTINGS = Object.freeze({
  ASC: 'asc',
  DESC: 'desc',
  ALPHABET_ASC: 'alphabet-asc',
  ALPHABET_DESC: 'alphabet-desc',
  CONTACTS_ALPHABET_ASC: 'lastname:asc,name:asc,created_at:desc',
  CONTACTS_ALPHABET_DESC: 'lastname:desc,name:desc,created_at:desc',
  CONTACTS_DATE_ASC: 'created_at:asc,lastname:asc,name:asc',
  CONTACTS_DATE_DESC: 'created_at:desc,lastname:asc,name:asc',
  DOSSIERS_ALPHABET_ASC: 'name:asc,updated_at:desc,created_at:desc',
  DOSSIERS_ALPHABET_DESC: 'name:desc,updated_at:desc,created_at:desc',
  DOSSIERS_DATE_ASC: 'updated_at:asc,created_at:asc,name:asc',
  DOSSIERS_DATE_DESC: 'updated_at:desc,created_at:desc,name:asc',
  DATE_ASC: 'date-asc',
  DATE_DESC: 'date-desc',
  get ALL() {
    return [
      this.ALPHABET_ASC,
      this.ALPHABET_DESC,
      this.DATE_ASC,
      this.DATE_DESC,
    ];
  },
  get CONTACTS() {
    return [
      this.CONTACTS_ALPHABET_ASC,
      this.CONTACTS_ALPHABET_DESC,
      this.CONTACTS_DATE_ASC,
      this.CONTACTS_DATE_DESC,
    ];
  },
  get DEFAULT() {
    return [this.ASC, this.DESC];
  },
  get DOSSIERS() {
    return [
      this.DOSSIERS_ALPHABET_ASC,
      this.DOSSIERS_ALPHABET_DESC,
      this.DOSSIERS_DATE_ASC,
      this.DOSSIERS_DATE_DESC,
    ];
  },
});

const REFRESH_STATUS = Object.freeze({
  COMPLETED: 'completed',
  PROCESSING: 'processing',
  UNKNOWN: 'unknown',
});

const TX = Object.freeze({
  MESSAGE_STATUS: {
    ENQUEUED: 'enqueued',
    FAILURE: 'failure',
    PROCESSING: 'processing',
    SUCCESS: 'success',
  },
  TYPE: {
    RECEIVED: 'received',
    SENT: 'sent',
  },
});

// Must be the last
const ICONS = Object.freeze({
  [POSITIONINGS.GRID]: mdiViewGridOutline,
  [POSITIONINGS.LIST]: mdiViewListOutline,
  [SORTINGS.ASC]: mdiSortAscending,
  [SORTINGS.DESC]: mdiSortDescending,
  [SORTINGS.ALPHABET_ASC]: mdiSortAlphabeticalAscending,
  [SORTINGS.ALPHABET_DESC]: mdiSortAlphabeticalDescending,
  [SORTINGS.DATE_ASC]: mdiSortCalendarAscending,
  [SORTINGS.DATE_DESC]: mdiSortCalendarDescending,
  [SORTINGS.CONTACTS_ALPHABET_ASC]: mdiSortAlphabeticalAscending,
  [SORTINGS.CONTACTS_ALPHABET_DESC]: mdiSortAlphabeticalDescending,
  [SORTINGS.CONTACTS_DATE_ASC]: mdiSortCalendarAscending,
  [SORTINGS.CONTACTS_DATE_DESC]: mdiSortCalendarDescending,
  [SORTINGS.DOSSIERS_ALPHABET_ASC]: mdiSortAlphabeticalAscending,
  [SORTINGS.DOSSIERS_ALPHABET_DESC]: mdiSortAlphabeticalDescending,
  [SORTINGS.DOSSIERS_DATE_ASC]: mdiSortCalendarAscending,
  [SORTINGS.DOSSIERS_DATE_DESC]: mdiSortCalendarDescending,
  [TX.TYPE.RECEIVED]: mdiArrowBottomLeftBoldOutline,
  [TX.TYPE.SENT]: mdiArrowTopRightBoldOutline,
});

export default Object.freeze({
  COLORS_LIST,
  COUNTRIES,
  FILE_TASKS,
  NFT_TYPES,
  POSITIONINGS,
  SORTINGS,
  REFRESH_STATUS,
  TX,
  ICONS,
});
