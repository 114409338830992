const certificateBuilder = {
  buildVaultHtml(data) {
    return `
    <!DOCTYPE html>
    <html lang="en">
      <head>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>Certificate ${data.process_id}}</title>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,700;1,300&family=Roboto+Mono&display=swap"
          rel="stylesheet"
        />
        <style>
          * {
            box-sizing: border-box;
          }
          body {
            font-family: 'Open Sans', sans-serif;
            margin: 0;
          }
          #heading {
            text-align: center;
          }
          #heading img {
            height: 140px;
            width: 140px;
          }
          #heading h1 {
            padding: 16px 0;
          }
          #heading h2 {
            padding: 8px 0;
          }
          #heading p {
            font-style: italic;
          }
          #metadata,
          #certification {
            margin: 32px 8px;
          }
          #metadata ul {
            list-style-type: none;
          }
          #metadata ul li {
            padding: 4px 0;
            font-size: 0.9rem;
            overflow-wrap: break-word;
          }
          #certification p {
            padding: 8px;
            line-height: 1.5rem;
            text-align: center;
          }
          #certification ol li {
            padding: 4px;
            overflow-wrap: break-word;
          }
          .font-bold {
            font-weight: 700;
          }
          .font-monospace {
            font-family: 'Roboto Mono', monospace;
          }
        </style>
      </head>
      <body>
        <div id="heading">
          <img src="${data.heading.srcLogo}" alt="Logo Commercio" />
          <h2>Certificate of Blockchain notarization and eArchive</h2>
          <h1>${data.heading.name}</h1>
          <p>${data.heading.fullName}</p>
        </div>
        <div id="metadata">
          <ul>
            <li>
              <span class="font-bold">Date</span>
              <span>:</span>
              <span>${data.metadata.timestamp}</span>
            </li>
            <li>
              <span class="font-bold">Filename</span>
              <span>:</span>
              <span>${data.metadata.fileName}</span>
            </li>
            <li>
              <span class="font-bold">Document Hash</span>
              <span>:</span>
              <span class="font-monospace">${data.metadata.hash}</span>
            </li>
            <li>
              <span class="font-bold">Transaction Hash</span>
              <span>:</span>
              <span class="font-monospace">${data.metadata.txHash}</span>
            </li>
            <li>
              <span class="font-bold">Messagge UUID</span>
              <span>:</span>
              <span class="font-monospace">${data.metadata.documentId}</span>
            </li>
          </ul>
        </div>
        <div id="certification">
          <p>This attest that the document has been notarized in the Commercio
          Blockchain thus proving that the document existed in its current form at
          the time of notarization.<br />With the corrisponding transaction and
          hash you can use this document as proof of certify that:</p>
          <ol type="a">
            <li>This document existed in its current form (Hash footprint) on date ${data.certification.timestamp}</li>
            <li>This document has not been altered in any way</li>
            <li>This document has been stored in a secure vault and will be available
            till ${data.certification.membershipExpiration} or more if storage service will be renewed</li>
          </ol>
        </div>
      </body>
    </html>
    `;
  },
};

export default certificateBuilder;
