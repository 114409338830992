import { LISTINGS, STAKE } from '@/constants';

export const listings = {
  [LISTINGS.POSITIONINGS.GRID]: 'Grid',
  [LISTINGS.POSITIONINGS.LIST]: 'List',
  [LISTINGS.SORTINGS.ASC]: 'Ascending',
  [LISTINGS.SORTINGS.DESC]: 'Descending',
  [LISTINGS.SORTINGS.ALPHABET_ASC]: 'Alphabet asc',
  [LISTINGS.SORTINGS.ALPHABET_DESC]: 'Alphabet desc',
  [LISTINGS.SORTINGS.DATE_ASC]: 'Date asc',
  [LISTINGS.SORTINGS.DATE_DESC]: 'Date desc',
  [LISTINGS.SORTINGS.CONTACTS_ALPHABET_ASC]: 'Alphabet asc',
  [LISTINGS.SORTINGS.CONTACTS_ALPHABET_DESC]: 'Alphabet desc',
  [LISTINGS.SORTINGS.CONTACTS_DATE_ASC]: 'Date asc',
  [LISTINGS.SORTINGS.CONTACTS_DATE_DESC]: 'Date desc',
  [LISTINGS.SORTINGS.DOSSIERS_ALPHABET_ASC]: 'Alphabet asc',
  [LISTINGS.SORTINGS.DOSSIERS_ALPHABET_DESC]: 'Alphabet desc',
  [LISTINGS.SORTINGS.DOSSIERS_DATE_ASC]: 'Date asc',
  [LISTINGS.SORTINGS.DOSSIERS_DATE_DESC]: 'Date desc',
  [LISTINGS.TX.TYPE.RECEIVED]: 'Inbound',
  [LISTINGS.TX.TYPE.SENT]: 'Outbound',
  [STAKE.MSG_TYPE.DELEGATE]: 'Delegate',
  [STAKE.MSG_TYPE.REWARDS]: 'Rewards',
  [STAKE.MSG_TYPE.UNDELEGATE]: 'Undelegate',
};